import React from 'react';
import { Fade } from 'react-awesome-reveal';
import { Container } from 'react-bootstrap';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import useMediaQuery from '@mui/material/useMediaQuery';
import Title from '../Title/Title';
import './Contact.css';

export const contactData = [
  {
    name: 'envelope',
    to: `mailto:${'pejodogstrainer@gmail.com'}`,
    value: 'pejodogstrainer@gmail.com',
  },
  {
    name: 'phone',
    to: 'tel:+48726881404',
    value: '726881404',
  },
];
const Contact = () => {
  const isMobileAndTabletView = useMediaQuery('(max-width:850px)');

  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "contact-background.jpeg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );
  const imageData = data.desktop.childImageSharp.fluid;

  return (
    <BackgroundImage
      Tag="section"
      id="contact-section"
      fluid={imageData}
      backgroundColor="#040e18"
      style={{ backgroundPositionY: '5%' }}
    >
      <div
        id="contact"
        style={{ backgroundImage: 'none', backgroundColor: '#86a14b96', padding: '15rem 0 6rem 0' }}
      >
        <Container>
          <Title title="Kontakt" />
          <Fade direction="up" triggerOnce>
            <div className="contact-wrapper">
              <p className="contact-wrapper__text">Masz pytania?</p>
              <div className="social-links">
                {contactData.map(({ name, value, to }) => {
                  return isMobileAndTabletView ? (
                    <a
                      key={name}
                      href={to}
                      rel="noopener noreferrer"
                      target="_blank"
                      aria-label={name}
                      style={{ fontSize: '3rem' }}
                    >
                      <i className={`fa fa-${name || 'refresh'} fa-inverse`} />
                    </a>
                  ) : (
                    <button
                      className="integration-checklist__copy-button"
                      key={name}
                      onClick={() => navigator.clipboard.writeText(value)}
                      aria-label={name}
                      type="button"
                      style={{ fontSize: '3rem' }}
                    >
                      <i className={`fa fa-${name || 'refresh'} fa-inverse`} />
                    </button>
                  );
                })}
              </div>
              <p
                className="contact-wrapper__text"
                style={{ paddingBottom: '13px', paddingTop: '20px' }}
              >
                Chcesz zacząć współpracę?
              </p>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="cta-btn cta-btn--resume"
                href="/formularz"
              >
                Wypełnij formularz
              </a>
              <p className="contact-wrapper__text">Odezwę się i&nbsp;ustalimy&nbsp;szczegóły!</p>
            </div>
          </Fade>
        </Container>
      </div>
    </BackgroundImage>
  );
};

export default Contact;
