import React from 'react';

import './Clock.css';

const Clock = ({ id, minutes }) => {
  return (
    <div style={{ width: '250px', height: '250px', position: 'absolute' }}>
      <svg preserveAspectRatio="none" viewBox="0 0 200 200" transform="rotate(270) scale(1 -1)">
        <circle
          cx="100"
          cy="100"
          r="80"
          strokeWidth="2"
          stroke="#779B2F"
          fill="transparent"
          strokeLinecap="round"
        />
        <circle
          cx="100"
          cy="100"
          r="80"
          strokeDasharray="600,600"
          strokeWidth="3"
          strokeDashoffset="0"
          strokellinecap="round"
          stroke="white"
          fill="none"
        >
          <animate
            id={id}
            attributeType="XML"
            attributeName="stroke-dashoffset"
            from="0"
            to={minutes * 10}
            dur="3s"
            repeatCount="1"
            fill="freeze"
          />
        </circle>
      </svg>
    </div>
  );
};

export default Clock;
