import React from 'react';
import { Helmet } from 'react-helmet';
import App from '../components/App';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/main.scss';

export default ({ location }) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Myśl jak pies</title>
        <html lang="pl" />
        <base href="pejodogstrainer.pl" />

        <meta
          name="keywords"
          content="pejo, pejodogstrainer, behawiorysta, bechawiorysta, Rzeszów, trener psów Rzeszów, trener psów, pejo Rzeszów, pies, trening, psy, pejo, trener Rzeszów, behawiorysta Rzeszów, szkolenie, szkolenie psów Rzeszów, szkolenie psów"
        />
        <meta
          name="description"
          content="Trener psów Mateusz Pejo Dańko. Uczę myśleć jak pies. Specjalizuję się w pozytywnym szkoleniu czworonogów."
        />
        <meta property="og:title" content="Trener psów. Behawiorysta psów Rzeszów. Myśl jak pies" />
        <meta property="og:site_name" content="pejodogstrainer.pl" />
        <meta
          property="og:description"
          content="Trener psów Mateusz Pejo Dańko. Uczę myśleć jak pies. Specjalizuję się w pozytywnym szkoleniu czworonogów."
        />
        <meta property="og:url" content="https://pejodogstrainer.pl/" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="pl_PL" />
      </Helmet>
      <App location={location} />
    </>
  );
};
