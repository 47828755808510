import React from 'react';

import { domService } from '../../shared';
import components from './IndividualPrice.styles';

const IndividualPrice = () => {
  const { Fade, Box, Btn } = components;
  return (
    <Fade>
      <Box>
        <div>Cena ustalana indywidualnie</div>
        <Btn onClick={() => domService.scrollTo('contact-section')}>Zapytaj</Btn>
      </Box>
    </Fade>
  );
};

export default IndividualPrice;
