export const plansCategories = [
  {
    id: 'behavioralConsultation',
    title: 'Konsultacje behawioralne',
    image: 'plan-category-2.jpeg',
    imageStyle: { backgroundPositionY: '70%' },
    popup: false,
    plans: [
      {
        key: 'introducing',
        id: 'introducing',
        iconFileName: 'icon-intro-dog.png',
        progress: 1,
        title: 'Pierwsza konsultacja behawioralna',
        subtitile: 'wywiad, analiza przypadku, przygotowanie&nbsp;zaleceń',
        description: `
                Konsultacja behawioralna polega na&nbsp;przeprowadzeniu obszernego wywiadu z&nbsp;opiekunem oraz obserwacji zachowania psa na&nbsp;podstawie przesłanych wcześniej nagrań i/lub w trakcie spotkania. Następnie przeprowadzana jest analiza behawioralna psa mająca na&nbsp;celu identyfikację przyczyn niepożądanego zachowania. Na&nbsp;jej podstawie przygotowywane są&nbsp;zalecenia do dalszej pracy z&nbsp;psem zawierające potrzebne do&nbsp;wprowadzenia zmiany mające na&nbsp;celu eliminację problemów. Zalecenia przesyłane są&nbsp;wybraną drogą kontaktu w&nbsp;ciągu 2-3 dni. Po&nbsp;otrzymaniu zaleceń decyzja o&nbsp;dalszej współpracy podejmowana jest przez klienta.
        `,
        meetings: 1,
        duration: 120,
        price: 220,
        allMeetings: 1,
        totalPrice: 220,
      },
      {
        key: 'introducingOnline',
        id: 'introducingOnline',
        iconFileName: 'online-dog.png',
        progress: 1,
        title: 'Pierwsza konsultacja behawioralna online',
        subtitile: 'wywiad online, analiza przypadku, przygotowanie zaleceń',
        description:
          'Konsultacja behawioralna polega na przeprowadzeniu obszernego wywiadu z&nbsp;opiekunem oraz obserwacji zachowania psa na&nbsp;podstawie przesłanych wcześniej nagrań. Następnie przeprowadzana jest analiza behawioralna psa mająca na&nbsp;celu identyfikację przyczyn niepożądanego zachowania. Na&nbsp;jej podstawie przygotowywane są&nbsp;zalecenia do&nbsp;dalszej pracy z&nbsp;psem zawierające potrzebne do&nbsp;wprowadzenia zmiany mające na celu eliminację problemów. Zalecenia przesyłane są&nbsp;wybraną drogą kontaktu w&nbsp;ciągu 2-3 dni. Po&nbsp;otrzymaniu zaleceń decyzja o&nbsp;dalszej współpracy podejmowana jest przez klienta. ',
        meetings: 1,
        duration: 120,
        price: 220,
        allMeetings: 1,
        totalPrice: 220,
      },
      {
        key: 'nextMeeting',
        id: 'nextMeeting',
        iconFileName: 'icon-pro-dog.png',
        progress: 2,
        title: 'Kolejna konsultacja behawioralna',
        subtitile: 'wdrażanie zaleceń, nadzór&nbsp;i&nbsp;prowadzenie',
        description:
          'Zajęcia polegają na wspólnej realizacji przygotowanych wcześniej zaleceń, organizacji spotkania dostosowując środowisko w celu zaradzenia problemom psa i poprawie jego dobrostanu lub weryfikacji pracy opiekuna oraz przekazania uwag po wdrożeniu zmian przekazanych w zaleceniach.',
        meetings: 1,
        duration: 60,
        price: 140,
        allMeetings: 1,
        totalPrice: 140,
        additionalText: 'Zajęcia odbywają się w&nbsp;miejscu wskazanym przez&nbsp;klienta.',
        longDescription: `
        <br/><b>
        Zajęcia polegają na wspólnej realizacji przygotowanych wcześniej zaleceń, organizacji spotkania dostosowując środowisko w&nbsp;celu zaradzenia problemom psa i&nbsp;poprawie jego dobrostanu lub weryfikacji pracy opiekuna oraz przekazania uwag po&nbsp;wdrożeniu zmian przekazanych w&nbsp;zaleceniach.
        `,
      },
    ],
  },
  {
    id: 'individualTraining',
    title: 'Szkolenia indywidualne',
    image: 'plan-category-5.jpeg',
    imageStyle: { backgroundSize: '105%', backgroundPositionY: '25%' },
    popup: true,
    plans: [
      {
        key: 'kindergarten',
        id: 'kindergarten',
        iconFileName: 'icon-puppy.png',
        modalImage: 'plan-modal-kindergarten.jpg',
        progress: 1,
        subtitile: 'do 6 msc życia',
        title: 'Psie przedszkole',
        description:
          'Szkolenie przeznaczone jest dla szczeniąt pomiędzy drugim, a&nbsp;szóstym miesiącem życia i&nbsp;składa się z&nbsp;10 spotkań (kurs) lub wybranych lekcji (zakres uzgadnia się&nbsp;indywidualnie).',
        meetings: 1,
        duration: 60,
        price: 120,
        allMeetings: 10,
        totalPrice: 1100,
        additionalText: 'Zajęcia odbywają się w&nbsp;miejscu wskazanym przez&nbsp;klienta.',
        longDescription: `
        <br/>Szkolenie przeznaczone jest dla szczeniąt pomiędzy drugim, a&nbsp;szóstym miesiącem życia i&nbsp;składa się z&nbsp;10 spotkań (kurs) lub wybranych lekcji (zakres uzgadnia się&nbsp;indywidualnie).
        <br/><br/><b>Program szkolenia</b><br/><br/>
        <ol>
        <li>Ocena warunków psa w&nbsp;domu - praktyczne wskazówki jak poprawić komfort psa i&nbsp;opiekuna.</li>
        <li>Ocena spacerów -&nbsp;praktyczne wskazówki i&nbsp;instruktaż.</li>
        <li>Nauka&nbsp;czystości.</li>
        <li>Socjalizacja&nbsp;szczeniaka.</li>
        <li>Zapobieganie i&nbsp;eliminacja zachowań takich jak skakanie na ludzi, podgryzanie przedmiotów i&nbsp;członków rodziny, frustracja i&nbsp;stres podczas zostawiania psa samego w&nbsp;domu.</li>
        </ol>
        <br/>
        Wstęp do podstaw posłuszeństwa<br/><br/>
        <ol>
        <li>Zastosowanie klikera i&nbsp;kontakt&nbsp;wzrokowy.</li>
        <li>Targetowanie.</li>
        <li>Prawidłowa zabawa.</li>
        <li>Chodzenie na luźnej smyczy.</li>
        <li>Przywołanie.</li>
        <li>Siad.</li>
        </ol>
        Organizator zastrzega sobie prawo do modyfikacji zakresu szkolenia dostosowując go do indywidualnych możliwości psa.
        <br/>
        <br/>
        `,
      },
      {
        key: 'basics',
        id: 'basics',
        iconFileName: 'icon_dog_5.png',
        modalImage: 'plan-modal-basics.jpeg',
        progress: 2,
        subtitile: 'od 6 msc życia',
        title: 'Podstawy posłuszeństwa',
        description:
          'Szkolenie przeznaczone jest dla psów powyżej szóstego miesiąca życia i&nbsp;składa się z&nbsp;10 spotkań (kurs) lub wybranych lekcji (zakres uzgadnia się&nbsp;indywidualnie).',
        meetings: 1,
        duration: 60,
        allMeetings: 10,
        price: 120,
        totalPrice: 1100,
        additionalText: 'Zajęcia odbywają się w&nbsp;miejscu wskazanym przez&nbsp;klienta.',
        longDescription: `<br/>
        Szkolenie przeznaczone jest dla psów powyżej szóstego miesiąca życia i&nbsp;składa się z&nbsp;10 spotkań (kurs) lub wybranych lekcji (zakres uzgadnia się&nbsp;indywidualnie). 
        <br/><br/>
            
        <b>Zakres szkolenia</b><br/><br/>
        <ol>
        <li>Zastosowanie klikera i&nbsp;kontakt&nbsp;wzrokowy.</li>
        <li>Targetowanie.</li>
        <li>Siad.</li>
        <li>Waruj/leżeć.</li>
        <li>Stój.</li>
        <li>Zostań.</li>
        <li>Dostawianie do nogi i&nbsp;chodzenie przy&nbsp;nodze.</li>
        <li>Przywołanie.</li>
        <li>Prawidłowa zabawa.</li>
        <li>Zostaw/weź.</li>
        <li>Na miejsce.</li>
        </ol>
        Organizator zastrzega sobie prawo do modyfikacji zakresu szkolenia dostosowując go do indywidualnych możliwości psa.
        <br/>
        <br/>
        `,
      },
      {
        key: 'advanced',
        id: 'advanced',
        iconFileName: 'icon-pro-dog.png',
        modalImage: 'plan-modal-advanced.jpeg',
        progress: 3,
        subtitile: 'po ukończeniu podstaw',
        title: 'Zaawansowane posłuszeństwo',
        description:
          'Szkolenie przeznaczone jest dla psów, które ukończyły szkolenie podstawowe i&nbsp;składa się z&nbsp;10 spotkań (kurs) lub wybranych lekcji (zakres uzgadnia się&nbsp;indywidualnie).',
        meetings: 1,
        duration: 60,
        price: 150,
        allMeetings: 10,
        totalPrice: 1300,
        additionalText: 'Zajęcia odbywają się w&nbsp;miejscu wskazanym przez&nbsp;klienta.',
        longDescription: `<br/>Szkolenie przeznaczone jest dla psów, które ukończyły szkolenie podstawowe i&nbsp;składa się z&nbsp;10 spotkań (kurs) lub wybranych lekcji (zakres uzgadnia się&nbsp;indywidualnie). 
        <br/><br/>
        Program szkolenia obejmuje zajęcia praktyczne obrazujące kolejne etapy szkoleń, na podstawie poprawnie utrwalonych&nbsp;komend. 
        <br/><br/>
        <b>Zakres szkolenia</b><br/><br/>
        <ol>
        <li>Wydłużenie czasu trwania komend -&nbsp;praca nad pozostawaniem psa w&nbsp;komendzie przez określony&nbsp;czas</li>
        <li>Praca na odległości -&nbsp;wykonywanie komend wydawanych z&nbsp;odległości.</li>
        <li>Praca w&nbsp;rozproszeniach -&nbsp;czym są rozproszenia, stopniowanie&nbsp;rozproszeń.</li>
        <li>Praca nad precyzją wykonywanych komend -&nbsp;poprawa czasu reakcji psa po wydaniu komendy, korekcja pozycji psa w&nbsp;komendzie.</li>
        </ol>
        Organizator zastrzega sobie prawo do modyfikacji zakresu szkolenia dostosowując go do indywidualnych możliwości psa.
        <br/>
        <br/>
        `,
      },
    ],
  },
  {
    id: 'petSitting',
    title: 'Pet sitting',
    image: 'petsitting.jpeg',
    imageStyle: { backgroundSize: '110%', backgroundPositionY: '30%' },
    popup: false,
    plans: [
      {
        key: 'firstWalk',
        id: 'firstWalk',
        iconFileName: 'icon-intro-dog.png',
        progress: 1,
        title: 'Pierwszy spacer',
        subtitile: 'spacer zapoznawczy z&nbsp;psem i&nbsp;opiekunem',
        description:
          'Spotkanie z&nbsp;opiekunem pozwalające na&nbsp;zapoznanie się&nbsp;z&nbsp;psem i&nbsp;przygotowanie go&nbsp;do&nbsp;wspólnych spacerów.',
        meetings: 1,
        duration: 60,
        price: 100,
        allMeetings: 1,
        totalPrice: 100,
      },
      {
        key: 'nextWalk',
        id: 'nextWalk',
        iconFileName: 'icon_dog_3.png',
        progress: 2,
        title: 'Kolejny spacer',
        subtitile: 'spacer z psem',
        description:
          'Wspólny spacer z&nbsp;psem nastawiony na&nbsp;zaspokojenie potrzeb psa w&nbsp;zakresie węszenia, eksploracji oraz aktywności ruchowej.',
        meetings: 1,
        duration: 60,
        price: 70,
        allMeetings: 1,
        totalPrice: 70,
      },
      {
        key: 'homeSitting',
        id: 'homeSitting',
        iconFileName: 'icon-intro-dog.png',
        progress: 0,
        title: 'Opieka nad psem',
        subtitile: 'indywidualna opieka nad psem w&nbsp;jego&nbsp;domu',
        description:
          'Opieka nad psem w domu klienta. Zakres opieki, cena i&nbsp;koszt usługi ustalane są&nbsp;indywidualnie w zależności od&nbsp;liczby zwierząt, zakresu obowiązków (spacery, posiłki, podawanie leków itp.),&nbsp;czasu trwania opieki oraz odległości do&nbsp;miejsca świadczenia usługi. ',
        price: null,
      },
    ],
  },
];
