/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from 'react';
import parse from 'html-react-parser';
import { Fade } from 'react-awesome-reveal';

import './DiscountBar.css';
import { discounts } from '../../data';

const isInPast = (date) => {
  const dateToCheck = new Date(date);
  const now = new Date();
  return now > dateToCheck;
};

const DiscountBar = () => {
  const upToDateDiscounts = discounts ? discounts.filter((x) => !isInPast(x.endDate)) : [];
  if (upToDateDiscounts.length < 1) return <></>;
  return (
    <>
      {upToDateDiscounts.map(({ key, text }) => (
        <Fade direction="up" key={key}>
          <div className="discountBar">{parse(text)}</div>
        </Fade>
      ))}
    </>
  );
};

export default DiscountBar;
