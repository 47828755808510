import styled from '@emotion/styled';

export default {
  PlanCountersBox: styled.div`
    display: flex;
    min-height: 100px;
    min-width: 100px;
    justify-content: space-around;
    height: 100%;
    align-items: center;

    @media (max-width: 500px) {
      flex-direction: column;
    }

    @media (max-width: 990px) {
      margin-top: -4.5rem;
    }
    @media (max-width: 500px) {
      flex-direction: row;
      margin-top: -3.5rem;
    }
  `,
};
