import { Fade } from 'react-awesome-reveal';
import styled from '@emotion/styled';

export default {
  Fade: styled(Fade)`
    height: 100%;
    color: #00000050;
    font-weight: 600;
    font-size: 2.2rem;
    flex-direction: column;
  `,
  Box: styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  Btn: styled.button`
    background-color: rgb(124, 158, 55);
    color: rgb(255, 255, 255);
    font-weight: 600;
    padding: 10px 20px;
    font-size: 1.5rem;
    border: none;
    margin-top: 1.5rem;
  `,
};
