/* eslint-disable react/self-closing-comp */
import React from 'react';
import styled from '@emotion/styled';
const PawPawPaw = styled.svg`
  width: 2rem;
  height: 1.8rem;
  fill: #7e9e44;
  margin-left: 5px;
  margin-bottom: 5px;
`;

const PawIcon = () => {
  return (
    <PawPawPaw
      className="paw-paw-paw"
      id="Warstwa_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 827.33 754.07"
    >
      <path d="M592.89,754.07c-21.55-4.4-43.1-8.84-64.66-13.18-32.15-6.47-64.13-14.21-96.54-18.95-38.28-5.6-74.8,5.95-111.47,15.32-23.13,5.91-46.65,10.96-70.3,14.04-77.47,10.07-134.4-46.53-147.92-110.91-10.04-47.79,2.01-89.39,38.04-123.37,48.54-45.77,96.77-91.86,145.46-137.46,72.52-67.91,182.02-68.35,254.95-.64,50.69,47.06,101.4,94.15,150.56,142.79,40.75,40.31,45.46,89.68,26.76,141.68-20.07,55.82-67.68,88.71-124.36,88.09l-.53,2.6Z" />
      <path d="M390.52,141.98c.11,38.45-10.77,73.26-35.47,103.13-41.5,50.2-103.54,48.88-142.62-3.43-27.11-36.29-34.52-78.08-30.43-122.25,2.89-31.2,13.1-59.77,33.23-84.41,40.02-48.96,113.93-44.47,147.46,9.08,18.74,29.93,28.06,62.53,27.83,97.88Z" />
      <path d="M645.97,141.21c.68,42.05-11.86,79.32-41.04,110.11-29.55,31.17-67.32,38.95-101.01,21.13-16.59-8.78-30.65-21.03-40.18-37.15-35.55-60.09-37.04-121.5-4.49-183.23,9.98-18.93,26.05-32.56,44.86-42.58,30.68-16.34,66.05-11.35,93.95,13.52,31.37,27.97,45.26,64.15,47.88,105.31,.27,4.29,.04,8.6,.04,12.91Z" />
      <path d="M643.37,336.97c1.27-52.73,16.13-95.92,55.36-128.17,37.78-31.05,84.04-23.48,110.01,17.89,19.59,31.22,21.56,65.43,15.65,100.58-4.88,29.02-16.56,55.33-35.32,78.05-21.96,26.59-49.62,41.22-84.51,31.63-35-9.62-51.92-36.25-58.13-70.44-1.97-10.84-2.3-21.97-3.05-29.54Z" />
      <path d="M183.1,334.33c.36,21.2-3.19,41.6-12.56,60.77-22.36,45.72-71.51,58.16-112.93,28.46-25.06-17.97-39.61-43.22-48.53-72.05-11.29-36.49-13.08-73.21,.58-109.45,21.27-56.42,84.18-69.26,126.38-26.17,30.86,31.51,47.09,72.35,47.06,118.44Z" />
    </PawPawPaw>
  );
};

export default PawIcon;
