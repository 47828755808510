/* eslint-disable no-plusplus */
import React from 'react';
import PropTypes from 'prop-types';

const ProgressDots = ({ count, progress }) => {
  const renderDots = () => {
    const dots = [];
    for (let i = 0; i < count; i++) {
      dots.push(
        <div
          key={i}
          style={{
            width: '13px',
            height: '13px',
            backgroundColor: i < progress ? 'rgb(124, 158, 55)' : 'rgb(0, 0, 0, 0.08)',
            marginLeft: i > 0 ? '6px' : '',
            borderRadius: '50%',
            border: i < progress ? '3px solid rgb(124, 158, 55)' : '',
            marginTop: '10px',
          }}
        />
      );
    }
    return dots;
  };

  return <div style={{ display: 'flex' }}>{renderDots()}</div>;
};

ProgressDots.propTypes = {
  count: PropTypes.number,
  progress: PropTypes.number,
};

export default ProgressDots;
