import PropTypes from 'prop-types';
import React from 'react';
import parse from 'html-react-parser';
import { Fade } from 'react-awesome-reveal';
import { Col } from 'react-bootstrap';

import PlanIcon from './PlanIcon';
import { useIsDesktop } from '../../hooks';
import components from './PlanDescription.styles';
import styled from '@emotion/styled';

const ReadMoreLabel = styled.span`
  font-size: 1.6rem;
  color: grey;
  display: block;
  @media (max-width: 500px) {
    display: none;
  }
`;

const PlanDescription = ({ iconFileName, progress, subtitile, title, description, popup }) => {
  const { isDesktop } = useIsDesktop();
  const { PlanBox, TextSubtitle, Description, PlanDescriptionBox } = components;
  return (
    <Col lg={6} sm={12} style={{ display: 'flex', alignItems: 'center' }}>
      <Fade direction={isDesktop ? 'left' : ''} triggerOnce>
        <div className="plan-wrapper__text">
          <PlanDescriptionBox>
            <PlanIcon filename={iconFileName} progress={progress} />
            <PlanBox>
              <h3 className="plan-wrapper__text-title">{title}</h3>
              <TextSubtitle>{parse(subtitile)}</TextSubtitle>
              <div style={{ width: '100%' }}>
                <Description className="description">{parse(description)} </Description>

                {popup && <ReadMoreLabel>Czytaj więcej...</ReadMoreLabel>}
              </div>
            </PlanBox>
          </PlanDescriptionBox>
        </div>
      </Fade>
    </Col>
  );
};

PlanDescription.propTypes = {
  description: PropTypes.string,
  iconFileName: PropTypes.string,
  progress: PropTypes.number,
  subtitile: PropTypes.string,
  title: PropTypes.string,
};

export default PlanDescription;
