import React, { useState, useEffect } from 'react';
import { Fade } from 'react-awesome-reveal';
import { Container, Row, Col } from 'react-bootstrap';
import parse from 'html-react-parser';

import AboutImg from './AboutImg';
import Title from '../Title/Title';
import Tabs from './TabsList';
import './About.css';

const tabs = [
  { title: 'o mnie', image: 'about-me.jpg' },
  { title: 'jak&nbsp;pracuję?', image: 'positive-training.jpg' },
  {
    title: '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;doświadczenie&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;', // 24.09.2022 Byłem leniuszkiem i nie chciało mi się stylować
    titleLong: 'doświadczenie',
    image: 'certificates.jpg',
  },
];
const About = () => {
  const [isDesktop, setIsDesktop] = useState(false);
  const [tab, setTab] = useState(tabs[0]);

  useEffect(() => {
    setIsDesktop(window.innerWidth > 769);
  }, []);

  return (
    <section id="about">
      <Container>
        <div className="about-image-mobile">
          <AboutImg filename={tab.image} />
        </div>
        <Title title={tab.title} titleLong={tab.titleLong} className="about-title" />
        <Row className="about-wrapper">
          <Col md={12} sm={12}>
            <Fade direction={isDesktop ? 'left' : 'up'} triggerOnce>
              <Tabs
                onTabChange={(t) => setTab(tabs[t])}
                titles={tabs.map((singleTab) => parse(singleTab.title))}
              />
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;
