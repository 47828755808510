import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import useMediaQuery from '@mui/material/useMediaQuery';

import { plansCategories } from '../../data';
import Title from '../Title/Title';
import DiscountBar from './DiscountBar';
import PlanModal from './PlanModal';
import PlanRowList from './PlanRowList';
import components from './Plans.styles';
import OfferMenuItem from './OfferMenuItem';
import { PLANS_MOBILE_WIDTH } from './constants';
import { domService } from '../../shared';

const pejoHasNoTaste = true;

const Plans = () => {
  const [openPlan, setOpenPlan] = useState();
  const [activePlanCategory, setActivePlanCategory] = useState({});
  const isMobileView = useMediaQuery(`(max-width:${PLANS_MOBILE_WIDTH})`);

  const onItemClick = (tab) => {
    setActivePlanCategory(tab === activePlanCategory ? {} : tab);
    domService.scrollTo('title-oferta');
  };

  const { PlansMenu, PlansMenuItem, MobileItemContainer, ColorMenuItem } = components;

  return (
    <section id="plans">
      <Container>
        <div className="plan-wrapper">
          <Title title="oferta" />
          <PlansMenu>
            {plansCategories.map((tab) => {
              if (pejoHasNoTaste)
                return (
                  <ColorMenuItem key={tab.id}>
                    <PlansMenuItem
                      active={activePlanCategory.id === tab.id}
                      onClick={() => onItemClick(tab)}
                    >
                      {tab.title}
                    </PlansMenuItem>
                  </ColorMenuItem>
                );
              return isMobileView ? (
                <MobileItemContainer key={tab.id}>
                  <PlansMenuItem
                    active={activePlanCategory.id === tab.id}
                    onClick={() => onItemClick(tab)}
                  >
                    {tab.title}
                  </PlansMenuItem>
                </MobileItemContainer>
              ) : (
                <OfferMenuItem
                  filename={tab.image}
                  imageStyle={tab.imageStyle}
                  alt={tab.title}
                  key={tab.id}
                >
                  <PlansMenuItem
                    active={activePlanCategory.id === tab.id}
                    onClick={() => onItemClick(tab)}
                  >
                    {tab.title}
                  </PlansMenuItem>
                </OfferMenuItem>
              );
            })}
          </PlansMenu>
          <div style={{ fontSize: '1.5rem', paddingBottom: '10px' }}>
            Dojazd do klienta poza Rzeszowem <b> 1,5 PLN/KM</b>
          </div>
          <DiscountBar />
          <PlanRowList selectedPlan={activePlanCategory} setOpenPlan={setOpenPlan} />
          <PlanModal plan={openPlan} onClose={() => setOpenPlan()} />
        </div>
      </Container>
    </section>
  );
};

export default Plans;
