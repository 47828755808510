import * as React from 'react';
import Box from '@mui/material/Box';
import AboutImg from './AboutImg';

const TabPositiveTraining = () => {
  return (
    <>
      <Box className="about-me-box">
        <div className="about-me-text">
          <span className="highlight">Dowiedz się jak pracuję z psami</span> <br />
          Przez wiele lat ewolucji człowieka towarzyszyły nam&nbsp;psy. <br />
          Pomimo upływu lat nadal nie rozumiemy tego, co bezustannie próbują nam przekazać. Przez
          lata spędzone z&nbsp;ludźmi, psy jako jedyny gatunek wykształciły umiejętność odczytywania
          naszych gestów i&nbsp;emocji. Teraz to my musimy nauczyć się dostrzegać sygnały wysyłane
          przez naszych czworonożnych przyjaciół i&nbsp;w&nbsp;pełni je odczytywać. Musimy nawiązać
          z&nbsp;nimi dialog i&nbsp;otworzyć się na to, co próbują nam powiedzieć. Zależy mi na tym,
          aby opiekunowie lepiej rozumieli swojego pupila, byli świadomi jego potrzeb, a&nbsp;dzięki
          temu ich pies na pewno stanie się ich najlepszym&nbsp;przyjacielem. <br /> <br />
          Każdy pies jest inny, prawda? <br />W związku z&nbsp;tym&nbsp;nie&nbsp;istnieje jedna
          rada, czy&nbsp;jeden lek&nbsp;na&nbsp;konkretny psi&nbsp;przypadek. W&nbsp;swojej pracy
          kieruję się&nbsp;indywidualnym podejściem do&nbsp;każdego klienta. Tylko w&nbsp;taki
          sposób jestem w&nbsp;stanie zrozumieć przyczynę problemu i&nbsp;znaleźć rozwiązanie.
        </div>
      </Box>
      <div className="about-image-desktop">
        <AboutImg filename="positive-training.jpg" />
      </div>
    </>
  );
};

export default TabPositiveTraining;
