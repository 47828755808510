import styled from '@emotion/styled';
import { Col } from 'react-bootstrap';

export default {
  MainPlanRow: styled.div`
    transition: 0.1s;
    padding: 30px 0;

    ${({ popup }) =>
      popup &&
      `
        cursor: pointer;
        
        &:hover {
          background-color: rgb(226, 226, 226);
          border-radius: 10px;
        }
    `}

    @media (max-width: 500px) {
      padding: 15px 0;
    }
  `,
  PlanCountersCol: styled(Col)`
    margin: auto !important;
  `,
};
