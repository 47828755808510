import React from 'react';

import PlanRow from './PlanRow';

const PlanRowList = ({ selectedPlan, setOpenPlan }) => {
  if (!selectedPlan?.plans || selectedPlan.plans?.length < 1) return null;
  return (
    <>
      {selectedPlan.plans.map((row) => (
        <PlanRow
          {...row}
          popup={selectedPlan.popup}
          onPlanClick={() => {
            selectedPlan.popup && setOpenPlan(row);
          }}
        />
      ))}
    </>
  );
};

export default PlanRowList;
