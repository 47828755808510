import * as React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard } from '@fortawesome/free-regular-svg-icons';
import { faBone, faAward } from '@fortawesome/free-solid-svg-icons';
import AboutMeTab from './TabAboutMe';
import TabCertificates from './TabCertificates';
import TabPositiveTraining from './TabPositiveTraining';
import './About.css';

const StyledTabs = styled(Tabs)`
  .MuiTabs-indicator {
    background-color: #ffffffe5 !important;
  }
  @media (max-width: 600px) {
    .MuiTabs-flexContainer {
      border-right: 0;
      border-bottom: 1;
      flex-direction: row !important;
      justify-content: space-evenly;
      padding-bottom: 10px;
    }
    .MuiTabs-indicator {
      background-color: none !important;
    }
  }
`;
const StyledTab = styled(Tab)`
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.7px;
  text-transform: lowercase;
  color: #ffffff80 !important;
  border: none;
`;
const StyledBox = styled(Box)`
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}
// eslint-disable-next-line react/prop-types
const TabsList = ({ onTabChange, titles }) => {
  const [selectedTab, setSelectedTab] = React.useState(0);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
    onTabChange(newValue);
  };
  const isMobileView = useMediaQuery('(max-width:600px)');

  return (
    <StyledBox
      sx={{
        flexGrow: 1,
        bgcolor: 'transparent',
        display: 'flex',
      }}
    >
      <StyledTabs
        orientation="vertical"
        variant="scrollable"
        value={selectedTab}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: `${isMobileView ? 0 : 1}`, borderColor: '#ffffff30' }}
      >
        <StyledTab
          {...a11yProps(0)}
          label={
            isMobileView ? (
              <FontAwesomeIcon
                icon={faAddressCard}
                className={`tabs-icon ${selectedTab === 0 && 'checked-tab'}`}
              />
            ) : (
              <span style={{ fontFamily: 'Montserrat' }}>{titles[0]} </span>
            )
          }
        />
        <StyledTab
          {...a11yProps(0)}
          label={
            isMobileView ? (
              <FontAwesomeIcon
                icon={faBone}
                className={`tabs-icon ${selectedTab === 1 && 'checked-tab'}`}
              />
            ) : (
              <span style={{ fontFamily: 'Montserrat' }}>{titles[1]} </span>
            )
          }
        />
        <StyledTab
          {...a11yProps(0)}
          label={
            isMobileView ? (
              <FontAwesomeIcon
                icon={faAward}
                className={`tabs-icon ${selectedTab === 2 && 'checked-tab'}`}
              />
            ) : (
              <span style={{ fontFamily: 'Montserrat' }}>{titles[2]} </span>
            )
          }
        />
      </StyledTabs>
      {selectedTab === 0 && <AboutMeTab />}
      {selectedTab === 1 && <TabPositiveTraining />}
      {selectedTab === 2 && <TabCertificates />}
    </StyledBox>
  );
};
export default TabsList;
