import styled from '@emotion/styled';

export default {
  PlanBox: styled.div`
    width: 100%;
    padding-left: 5%;

    @media (max-width: 991px) {
      padding: 0 5%;
    }
  `,
  TextSubtitle: styled.h3`
    opacity: 0.15;
    font-weight: 700;
    font-size: 2.2rem;
    margin-top: -13px;
    padding-bottom: 10px;
    @media (max-width: 600px) {
      font-size: 1.8rem;
    }
  `,
  Description: styled.p`
    line-height: 25px;
    text-align: justify;
  `,
  PlanDescriptionBox: styled.div`
    display: flex;
    @media (max-width: 420px) {
      flex-wrap: wrap;
    }
  `,
};
