import styled from '@emotion/styled';

export default {
  InfoBox: styled.div`
    box-shadow: 2px 2px 4px #00000060;
    padding: 25px;
    display: flex;
    flex: 1;

    @media (min-width: 1200px) {
      overflow: auto;
    }
  `,
  PriceBox: styled.div`
    box-shadow: 2px 2px 4px #00000060;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    height: 100%;
    margin: 20px 0 0 0;

    @media (min-width: 1200px) {
      width: 30%;
      margin: 0 0 0 20px;
    }
  `,
};
