/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Fade } from 'react-awesome-reveal';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import { domService } from '../../shared';
import PawIcon from './PawIcon';

const Welcome = () => {
  const [isDesktop, setIsDesktop] = useState(false);
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "welcome-background.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );
  const imageData = data.desktop.childImageSharp.fluid;

  useEffect(() => {
    setIsDesktop(window.innerWidth > 769);
  }, []);

  return (
    <div>
      <BackgroundImage
        Tag="div"
        className="pejo-back-img"
        fluid={imageData}
        backgroundColor="#040e18"
        style={{ backgroundPositionY: '32%', backgroundSize: '110%' }}
      >
        <section id="welcome" style={{ backgroundColor: 'rgb(28 28 28 / 86%)', color: '#fff' }}>
          <Container>
            <Fade direction={isDesktop ? 'left' : 'up'} triggerOnce>
              <h1 className="welcome-title">
                Cześć, jestem&nbsp;Pejo
                <br />
                i&nbsp;nauczę Cię{' '}
                <span className="text-color-main">myśleć jak Twój&nbsp;pies.</span>
              </h1>
            </Fade>
            <Fade direction={isDesktop ? 'left' : 'up'} triggerOnce>
              <p className="welcome-cta" onClick={() => domService.scrollTo('about', 'center')}>
                <span
                  className="cta-btn cta-btn--welcome"
                  style={{ padding: '17px 40px', marginTop: '20px', lineHeight: '30px' }}
                >
                  <a>
                    Poznajmy&nbsp;się! <PawIcon />
                  </a>
                </span>
              </p>
            </Fade>
          </Container>
        </section>
      </BackgroundImage>
    </div>
  );
};

export default Welcome;
