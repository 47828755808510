import * as React from 'react';
import Box from '@mui/material/Box';
import AboutImg from './AboutImg';
import './About.css';

const TabAboutMe = () => {
  return (
    <>
      <Box className="about-me-box">
        <div className="about-me-text">
          <span className="highlight">Mam na imię Mateusz,</span>
          <br />
          jednak moi bliscy mówią na&nbsp;mnie&nbsp;Pejo. <br />
          Jestem behawiorystą i certyfikowanym instruktorem pozytywnego szkolenia psów
          metodą&nbsp;klikerową. Cały czas podnoszę swoje kwalifikacje, biorąc udział w&nbsp;różnego
          rodzaju kursach, warsztatach czy&nbsp;webinarach.
          <br />
          <br />
          <span className="highlight">Dlaczego&nbsp;psy?</span> <br />
          Psy były w&nbsp;moim życiu od kiedy pamiętam. Prawdziwe, szczere i&nbsp;bezgranicznie
          oddane. Mój tata był opiekunem psa w&nbsp;służbach mundurowych, a&nbsp;w&nbsp;naszym domu
          przez kilkanaście lat towarzyszył mi Dolar, mieszaniec&nbsp;jamnika i&nbsp;pinczera
          miniaturowego. Od 2020&nbsp;roku jest ze mną Jager. Średniej wielkości kundel oraz mój
          serdeczny przyjaciel. To&nbsp;właśnie on&nbsp;tak bardzo zaciekawił mnie swoim światem,
          że&nbsp;postanowiłem zostać trenerem i&nbsp;behawiorystą.
        </div>
      </Box>
      <div className="about-image-desktop">
        <AboutImg filename="about-me.jpg" />
      </div>
    </>
  );
};

export default TabAboutMe;
