import * as React from 'react';
import Box from '@mui/material/Box';
import parse from 'html-react-parser';
import AboutImg from './AboutImg';
import './About.css';

const data = [
  {
    title: 'Kurs Zoopsycholog/Behawiorysta spec.&nbsp;psy',
    paragraph: 'Nr&nbsp;licencji:  89/ZOOP/PAZiA/GW/22',
  },
  {
    title: 'Kurs Instruktor Szkolenia&nbsp;Psów',
    paragraph: 'Nr&nbsp;licencji: 2360/08/2021/PAZIA',
  },
  {
    title: 'Kurs Trenera Psów Obronnych z&nbsp;wykorzystaniem metod pozytywnych',
    paragraph: 'Nr&nbsp;licencji: 191/TRO/PAZiA/GW/22',
  },
  {
    title: 'Instruktor i Wykładowca',
    paragraph: `w Polska Akademia Zoopsychologii i Animaloterapii <a
      href="https://pazia.pl"
      rel="noopener noreferrer"
      target="_blank"
      class="about-link"
    >PAZIA</a>`,
  },
  {
    title: 'Współpraca z&nbsp;trenerem Krzysztofem Idziorem',
    paragraph: `
    którego możecie odwiedzić&nbsp;na&nbsp;
            <a
              href="https://sosnowazagroda.pl"
              rel="noopener noreferrer"
              target="_blank"
              class="about-link"
            >sosnowazagroda.pl</a>
    `,
  },
  { title: 'Liczne webinary i kursy doszkalające' },
];
const TabCertificates = () => {
  return (
    <>
      <Box className="about-me-box">
        <div>
          <ul>
            {data.map((item) => (
              <>
                <li className="highlight">{parse(item.title)}</li>
                {item.paragraph && <p>{parse(item.paragraph)}</p>}
              </>
            ))}
          </ul>
        </div>
      </Box>
      <div className="about-image-desktop">
        <AboutImg filename="certificates.jpg" />
      </div>
    </>
  );
};

export default TabCertificates;
