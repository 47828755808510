import React from 'react';
import PropTypes from 'prop-types';

import { PejoIcon } from '../../shared';
import ProgressDots from './ProgressDots';
import './PlanIcon.css';

const PlanIcon = ({ filename, progress }) => {
  return (
    <div className="main-pejo-icon">
      <div style={{ width: '70px' }}>
        <PejoIcon filename={filename} style={{ width: '70px', height: '70px' }} />
      </div>
      {progress !== undefined && <ProgressDots count={3} progress={progress} />}
    </div>
  );
};

PlanIcon.propTypes = {
  filename: PropTypes.string,
  progress: PropTypes.number,
};

export default PlanIcon;
