import PropTypes from 'prop-types';
import React from 'react';
import { Fade } from 'react-awesome-reveal';
import { Row, Col } from 'react-bootstrap';

import PlanDescription from './PlanDescription';
import PlanCounters from './PlanCounters';
import './PlanRow.css';
import { useIsDesktop } from '../../hooks';
import IndividualPrice from './IndividualPrice';
import components from './PlanRow.styles';
import styled from '@emotion/styled';

const ReadMoreBtn = styled.button`
  width: 100%;
  background-color: #333333dc;
  color: white;
  font-weight: 600;
  padding: 10px 20px;
  font-size: 1.5rem;
  margin-top: 10px;
  border: none;
  display: none;
  @media (max-width: 500px) {
    display: block;
  }
`;
const PlanRow = ({
  onPlanClick,
  iconFileName,
  progress,
  id,
  subtitile,
  title,
  description,
  meetings,
  duration,
  price,
  allMeetings,
  popup,
}) => {
  const { isDesktop } = useIsDesktop();

  const { MainPlanRow, PlanCountersCol } = components;
  return (
    <MainPlanRow onClick={onPlanClick} popup={popup}>
      <Row className="plan-row" style={{ marginBottom: '0 !important' }}>
        <PlanDescription
          description={description}
          iconFileName={iconFileName}
          progress={progress}
          subtitile={subtitile}
          title={title}
          popup={popup}
        />
        <PlanCountersCol lg={6} sm={12}>
          {allMeetings > 0 ? (
            <Fade direction={isDesktop ? 'right' : 'up'} triggerOnce>
              <PlanCounters meetings={allMeetings} duration={duration} id={id} />
            </Fade>
          ) : (
            <IndividualPrice />
          )}
        </PlanCountersCol>
      </Row>
      {price > 0 && (
        <Row className="price-row">
          <Col lg={12}>
            <Fade direction={isDesktop ? 'right' : 'up'} triggerOnce>
              <div className="price-box">
                <span className="price-text">
                  Cena {meetings > 1 && 'pakietu '}
                  <span style={{ color: '#000' }}>{meetings}</span>&nbsp;
                  {meetings === 1 ? 'spotkania' : 'spotkań'}:
                </span>
                <span className="price">{price} PLN</span>
                {popup && <ReadMoreBtn>Czytaj więcej</ReadMoreBtn>}
              </div>
            </Fade>
          </Col>
        </Row>
      )}
    </MainPlanRow>
  );
};

PlanRow.propTypes = {
  onPlanClick: PropTypes.func,
  description: PropTypes.string,
  duration: PropTypes.number,
  iconFileName: PropTypes.string,
  id: PropTypes.string,
  meetings: PropTypes.number,
  progress: PropTypes.number,
  subtitile: PropTypes.string,
  title: PropTypes.string,
  price: PropTypes.number,
  allMeetings: PropTypes.number,
};

export default PlanRow;
