import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake } from '@fortawesome/free-regular-svg-icons';
import VisibilitySensor from 'react-visibility-sensor';

import { PejoCountUp } from '../../shared';
import './PlanCounters.css';
import Clock from './Clock';
import components from './PlanCounters.styles';

const PlanCounters = ({ meetings, duration, id }) => {
  return (
    <>
      <div className="meeting-card">
        <FontAwesomeIcon icon={faHandshake} style={{ opacity: 0.8 }} />
        <div className="meeting-card__description">
          <PejoCountUp start={0} end={meetings} duration={3} />
          &nbsp;
          <div> {meetings === 1 ? 'spotkanie' : 'spotkań'} </div>
        </div>
      </div>
      <div className="clock-card">
        <div className="clock-card__box">
          <div className="clock-card__box__countup">
            <PejoCountUp start={0} end={duration} duration={3} /> <div> minut </div>
          </div>
          <Clock minutes={duration > 60 ? 60 : duration} id={id} />
        </div>
      </div>
    </>
  );
};

PlanCounters.propTypes = {
  duration: PropTypes.number,
  meetings: PropTypes.number,
  id: PropTypes.string,
};

export default (props) => {
  const [rendered, setRendered] = useState(false);

  const onChange = (isVisible) => {
    if (isVisible && !rendered) setRendered(true);
  };

  const { PlanCountersBox } = components;

  return (
    <VisibilitySensor onChange={onChange}>
      <PlanCountersBox>{rendered && <PlanCounters {...props} />}</PlanCountersBox>
    </VisibilitySensor>
  );
};
