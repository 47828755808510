import BackgroundImage from 'gatsby-background-image';
import styled from '@emotion/styled';
import { PLANS_MOBILE_WIDTH } from './constants';

export default {
  PlansMenuItem: styled.div`
    color: white;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 2.5rem;
    height: 100%;
    width: 100%;
    background-color: #3333339d;
    padding: 0 10px;

    &:hover {
      background-color: #333333dc;
      transition: 0.2s;
      cursor: pointer;
    }

    ${(props) =>
      props.active &&
      `
    background-color: #333333dc;
    transition: 0.2s;
    cursor: pointer;
  `}

    @media (max-width: ${PLANS_MOBILE_WIDTH}) {
      font-size: 2.2rem;
      padding: 10px;
    }
  `,
  PlansMenu: styled.div`
    display: flex;
    font-size: 2.9rem;
    margin-bottom: 10px;

    @media (max-width: ${PLANS_MOBILE_WIDTH}) {
      flex-direction: column;
      font-size: 2.1rem;
      margin-bottom: 0;
    }
  `,
  MobileItemContainer: styled.div`
    @media (max-width: ${PLANS_MOBILE_WIDTH}) {
      margin-bottom: 10px;
    }
  `,
  ColorMenuItem: styled.div`
    height: 100px !important;
    margin: 10px;
    flex: 1;
    @media (max-width: ${PLANS_MOBILE_WIDTH}) {
      margin: 5px;
    }
  `,

  BackgroundImageStyled: styled(BackgroundImage)`
    display: flex;
    flex: 1;
    margin: 10px;
    height: 350px;
  `,
};
