import { useState, useEffect } from 'react';

export default function useOnScreen(ref) {
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    setIsDesktop(window.innerWidth > 769);
  }, []);

  return { isDesktop };
}
