import React from 'react';
import { Fade } from 'react-awesome-reveal';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';

const Title = ({ title, className, titleLong }) => (
  <Fade direction="up" triggerOnce>
    <h2 id={`title-${title}`} className={`section-title ${className || ''}`}>
      {parse(titleLong ? titleLong : title)}
    </h2>
  </Fade>
);

Title.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default Title;
